@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.nav {
  left: 50%;
  transform: translate(-50%);
  padding: 3px;
  position: fixed;
  top: 10px;
  backdrop-filter: blur(30px) opacity(0.9);
  border: 1px solid rgb(50, 50, 50);
  border-radius: 30px;
  z-index: 10;
  display: flex;

  &--links-wrapper {
    order: 2;
    .nav-link {
      text-decoration: none;
      color: $gray-100;
      letter-spacing: 0.3px;
      @extend %body;
      font-size: 16px;
      transition: all 0.2s;
      padding: 0.4rem 0.8rem;
      border-radius: 40px;

      &:hover,
      &:active {
        background: $primary;
      }

      &:first-of-type {
        padding: 0.4rem 3rem 0.4rem 0.7rem;
      }
    }
  }
}
