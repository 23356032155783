@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.contributors {
  @media #{$mq-xl} {
    max-width: 90%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    max-height: 40vh;
  }
  @media #{$mq-md} {
    margin: 10vh $margin-sm;
    min-height: 45vh;
  }
  display: flex;
  margin: 1rem $margin-sm;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;

  h1 {
    @extend %h1;
    font-size: 56px;
    line-height: 64px;
    color: $white;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
  }

  h2 {
    @extend %body-lg;
    color: $gray-200;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
  }
  p {
    @extend %body;
    color: $gray-200;
    max-width: 28rem;
    a {
      color: $white;
      transition: all 0.2s ease-out;
      &:hover {
        color: $primary;
      }
    }
  }
  
  &--text-wrapper {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 3rem 0;

    .column {
      display: flex;
      flex-direction: column;
    }
  }
  .button-group {
    a {
      @media #{$mq-md} { margin-right: 0.5rem;} 
    }
  }
  .grid {
    @media #{$mq-lg} {
      grid-template-columns: repeat(12, 1fr);
    }
    @media #{$mq-md} {
      grid-template-columns: repeat(10, 1fr);
    }
    display: grid;
    align-content: center;
    justify-content: center;
    grid-template-columns: repeat(8, 1fr);
  }

  .contributor {
    align-self: center;
    justify-self: center;
    padding-bottom: 0.5rem;

    a {
      &:hover {
        filter: brightness(0.8);
      }
    }
    .avatar {
      border-radius: 4px;
      @media #{$mq-md} {
        width: 3rem;
        height: 3rem;
      }
      width: 2rem;
      height: 2rem;
    }
  }
}
