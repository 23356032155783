@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.lightclients {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 5vh $margin-sm;
  }
  margin: 0 16px;
  flex-direction: column;
  display: flex;
  overflow-x: hidden;

  img {
    width: 100%;
  }

  .title {
    @extend %h1;
    font-size: 56px;
    line-height: 64px;
    color: $gray-100;
    font-weight: 600;
    margin-block-end: 0;
    text-align: center;
  }

  &--intro {
    h1 {
      @extend %h1;
      color: white;
      margin-block-end: 0;
    }
    p {
      @media #{$mq-lg} {
        max-width: 48rem;
      }
      @extend %body-lg;
      color: $gray-300;

      a {
        color: $gray-300;
        transition: 0.3s;
        &:hover {
          color: $primary;
        }
      }
    }
  }
  &--details {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 25rem);
    }
    display: grid;
    grid-template-columns: 1fr;

    .column {
      h2 {
        @extend %body-lg;
        color: $gray-100;
        margin-block-end: 0.5rem;
      }
      p {
        @extend %body;
        margin-block-start: 0.3rem;
        margin-block-end: 0.3rem;
        color: $gray-300;
      }
    }
  }
  &--button {
    width: 100%;
    margin: 3vh 0;
    align-self: center;
    justify-self: center;
  }

  .large-link {
    @extend %body-lg;
    text-decoration: none;
    color: $white;
    border: 4px solid $secondary;
    border-radius: 50px;
    padding: 1.2rem 3.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    transition: all 0.15s ease-out;
    &:hover {
      filter: brightness(0.7);
    }
    &:first-of-type {
      border: none;
      background: $primary;
    }
  }
}
