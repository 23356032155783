@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.timeline {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 0 $margin-sm;
    min-height: 30vh;
  }
  background-color: $primary;
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  justify-content: center;
  border-radius: 24px;

  h1 {
    font-size: 60px;
    line-height: 70px;
    margin-block-start: 0;
    margin-block-end: 0;
    letter-spacing: 0.05rem;
    color: $gray-100;
    font-weight: 600;
  }
  p {
    @extend %body;
    color: $gray-100;
    max-width: 30rem;
    margin-block-start: 0.5rem;
  }
  &--text-wrapper {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5rem;
      padding: 3rem 2rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 3rem 1rem 1.5rem 1rem;
    .tab-content-wrapper {
      @media #{$mq-md} {
        flex-direction: row;
        justify-content: space-between;
      }
      display: flex;
      flex-direction: column;
      .show-img {
        display: inline-flex;
        position: relative;
        width: 100%;
        @media #{$mq-md} {
          width: unset;
          max-width: 400px;
        }
        @media #{$mq-lg} {
          max-width: unset;
          width: 100%;
        }
      }
      .column {
        @media #{$mq-md} {
          min-width: 50%;
        }
        @media #{$mq-lg} {
          min-width: 60%;
        }
        display: flex;
        flex-direction: column;
        .accent-year {
          font-size: 120px;
          color: #1600A8;
          font-family: NeueMontrealMedium;
        }
        
        p {
          margin-top: 2rem;
        }
        &:nth-of-type(2) {
          @media #{$mq-md} {
            display: block;
          }
          display: none;
        }
      }
    }
  }

  .tabs-container {
    display: flex;
  }

  .unselected {
    display: none;
  }

  .tab {
    @media #{$mq-md} {
      margin: .4rem 1rem .4rem 0;
    }
    color: $white;
    cursor: pointer;
    transition: 0.2;
    margin: .5rem 1.5rem 0 0;
    &:hover {
      filter: brightness(.7);
    }
  }

  .activeTab {
    color: #1600A8;
    font-weight: 600;

  }
}
