@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.general {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 0;
  }
  margin: 0 8px;
  flex-direction: column;
  display: flex;

  &--about {
    @media #{$mq-md} {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 70px;

    }
    display: flex;
    flex-direction: column;
  }

  &--setup {
    margin: 0 8px;
    @media #{$mq-md} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 3rem 2rem;
    }
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    top: 2vh;
    border: 4px solid $secondary;
    border-radius: 24px;
    padding: 1.5rem 1rem;
    img {
      @media #{$mq-md} {
        justify-self: end;
        width: 50%;
        max-width: unset;
      }
      width: 100%;
      max-height: 370px;
      max-width: fit-content;
    }
  }

  &--tools {
    position: relative;
    top: 2vh;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
  }

  .title {
    @extend %h1;
    color: $white;
    font-weight: 500;
    margin-block-end: 0.5rem;
  }

  .text-wrapper {
    display: flex;
    flex-direction: column;
    h1,
    h2 {
      @extend %body-lg;
      color: rgb(149, 149, 149);
      max-width: 30rem;
    }
    h1::selection {
      background: $primary;
      color: $white;
    }
    .about-text {
      @media #{$mq-md} {
        margin-left: 1rem;
      }
      color: $white;
    }
  }
  .button-group {
    @media #{$mq-md} {
      flex-direction: row;
      align-items: center;
    }
    display: flex;
    flex-direction: column;
    a {
      @media #{$mq-md} {
        margin-right: 0.5rem;
      }
    }
  }
}

.general .cli {
  @media #{$mq-md} {
    border: 4px solid transparent;
    border-radius: 24px;
    width: 40%;
    height: 400px;
  }
  display: none;
}

.general .tools-wrapper {
  @media #{$mq-md} {
    grid-template-columns: repeat(4, 1fr);
  }
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: .25rem;
  width: 100%;
  margin-top: 1rem;

  .tool {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    @media #{$mq-md} {
      margin-right: 1rem;
      margin-bottom: 0;
    }

    a {
      @extend %body;
      color: lightgrey;
      text-decoration: none;
      transition: all 0.2s ease-out;
    }
    a:hover {
      filter: brightness(0.6);
    }

    img {
      width: 100%;
    }
  }
}
