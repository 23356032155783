@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.team {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 0 $margin-sm;
    min-height: 40vh;
  }
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  justify-content: center;
  border: 4px solid $secondary;
  border-radius: 24px;

  h1 {
    @media #{$mq-lg} {
      max-width: 70%;
    }
    @extend %h1;
    color: $gray-100;
    font-weight: 500;
  }

  h2 {
    @extend %body-lg;
    color: $gray-200;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
  }
  p {
    @extend %body;
    color: $gray-300;
    max-width: 28rem;
    margin-block-start: .5rem;
    margin-block-end: .5rem;
  }
  &--text-wrapper {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 2rem;
      padding: 3rem 2rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 1.5rem 1rem;

    .column {
      display: flex;
      flex-direction: column;
    }
  }
  .img {
    width: 100%;
    border-radius: 24px;
    max-width: 550px;
    object-fit: contain;
    object-position: top;
  }
}
