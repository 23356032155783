@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.community {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 5vh $margin-sm 0;
  }
  margin: 1rem $margin-sm;
  flex-direction: column;
  display: flex;

  img {
    width: 100%;
  }

  h1 {
    @media #{$mq-md} {
      max-width: 70%;
    }
    @extend %body-lg;
    color: $gray-100;
    font-weight: 500;
    margin-block-end: 0;
    min-height: 3rem;
  }
  p {
    @extend %body;
    color: $gray-300;
    max-width: 28rem;
  }
  &--double-col {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    margin-bottom: 0.5rem;
  }

  &--stats {
    position: relative;
    margin: 0.5rem 0;
    display: flex;
    flex-direction: column;
    img {
      position: relative;
      left: 0;
      z-index: -1;
    }
  }

  .card {
    position: relative;
    padding: 3rem 2rem 6rem;
    display: flex;
    border-radius: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .typescript {
    background: $secondary;
    text-align: left;
    img {
      position: absolute;
      width: 11rem;
      z-index: 0;
      bottom: 5%;
      right: 3%;
    }
  }

  .social {
    border: 4px solid $secondary;
    text-align: center;
    align-items: center;

    p {
      text-align: center;
    }
  }

  .community--stats .text-wrapper {
    padding: 5vh 0;

    h1 {
      @extend %h1;
      color: $gray-100;
      margin-block-end: 0;
    }
    p {
      @extend %body-lg;
      @media #{$mq-lg} {
        max-width: 45rem;
      }
      color: $gray-300;
      max-width: unset;
      margin-block-start: 0.5rem;
      margin-block-end: 0.5rem;
    }
    img {
      margin-top: 5vh;
    }
  }
  .button-group {
    a {
      @media #{$mq-md} { margin-right: 0 .5rem;} 
    }
  }
}
