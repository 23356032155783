@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.hero {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 0 $margin-sm 0;
  }
  margin: 10vh 8px;
  flex-direction: column;
  display: flex;
  position: relative;
  
  .text-wrapper {
    @media #{$mq-lg} {
      top: 20vh;
    }
    position: relative;
    top: 10vh;
    display: flex;
    flex-direction: column;
    h1 {
      @media #{$mq-md} {
        font-size: 60px;
        line-height: 70px;
      }
      @extend %h1;
      color: $white;
      font-size: 56px;
      line-height: 64px;
      font-weight: 500;
      margin-block-end: 1rem;
    }
    p {
      @extend %body-lg;
      color: white;
      max-width: 30rem;
    }
  }
  .button-group {
    a {
      margin-right: 0.5rem;
    }
  }
}
