@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.support {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 0 $margin-sm;
    min-height: 40vh;
  }
  display: flex;
  margin: 1rem 0;
  flex-direction: column;
  justify-content: center;

  h1 {
    @media #{$mq-md} {
      font-size: 60px;
      line-height: 70px;
    }
    margin-block-start: 0;
    margin-block-end: 0;
    color: $gray-100;
    font-weight: 500;
  }
  p {
    @extend %body;
    color: $gray-300;
    max-width: 28rem;
    margin-block-start: 0.5rem;
    margin-block-end: 0;
  }
  &--text-wrapper {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
      column-gap: 0.5rem;
      padding: 3rem 2rem;
    }
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 1rem;
    padding: 1.5rem 1rem;

    .column {
      display: flex;
      flex-direction: column;
    }
  }
  .large-link {
    @extend %body-lg;
    text-decoration: none;
    color: $white;
    border: 4px solid $secondary;
    border-radius: 50px;
    padding: 0.8rem 1.2rem;
    margin-bottom: 0.5rem;
    text-align: center;
    transition: all 0.15s ease-out;
    &:hover {
      filter: brightness(0.7);
    }
    &:first-of-type {
      border: none;
      background: $primary;
    }
  }
}
