// global color setup
:root {
  --gray-color-hue: 0;
  --gray-color-saturation: 5%;
}

// sass variables
$primary: #4e33ff;
$primary-dark: hsl(248, 100%, 50%);

$secondary: hsl(0, 0, 18%);
$secondary-dark: hsl(0, 0, 10%);

$gray-100: hsl(35, 38%, 94%); //#F5F0E9

$gray-200: hsl(35, 21%, 89%); //#F5F5F5
$gray-300: hsl(35, 11%, 77%); //#cac5bd

$white: hsl(var(--gray-color-hue), var(--gray-color-saturation), 100%);
$black: hsl(var(--gray-color-hue), var(--gray-color-saturation), 0%);
