@import 'src/styles/breakpoints.scss';
@import 'src/styles/colors.scss';
@import 'src/styles/typography.scss';

.vision {
  @media #{$mq-xl} {
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  @media #{$mq-md} {
    margin: 5vh $margin-sm;
  }
  margin: 0 16px;
  flex-direction: column;
  display: flex;

  img {
    width: 100%;
  }

  .title {
    @media #{$mq-md} {
      font-size: 56px;
      line-height: 64px;
      max-width: 50rem;
      align-self: center;
    }
    font-size: 40px;
    line-height: 40px;
    @extend %h1;
    color: $gray-100;
    font-weight: 500;
    margin-block-end: 0;
    text-align: center;
  }

  &--properties {
    @media #{$mq-md} {
      grid-template-columns: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
    margin: 5vh 0;
  }

  .property {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 60%;
    }
    h1 {
      @extend %body-lg;
      color: $gray-100;
      font-weight: 600;
      margin-block-end: 0;
      text-align: center;
    }
    p {
      @extend %body;
      color: $gray-300;
      max-width: 28rem;
      text-align: center;
      margin-block-start: 0.5rem;
    }
  }
}
