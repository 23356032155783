@import 'src/styles/colors';
@import 'src/styles/typography';

.button {
  @extend %link;
  font-weight: 500;
  position: relative;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 1.5rem;
  cursor: pointer;
  transition-duration: 0.15s;
  overflow: hidden;
  margin-bottom: .25rem;
  z-index: 1;

  &:hover,
  &:active {
    filter: brightness(0.9);
  }

  &__primary {
    background-color: $primary;
    text-decoration: none;
    color: $gray-100;
  }

  &__dark {
    background: transparent;
    box-sizing: border-box;
    border: 4px solid $secondary;
    text-decoration: none;
    color: $gray-100;
  }
}
